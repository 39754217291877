import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import SEO from "../components/seo"
import Amplify, { Storage, API, graphqlOperation } from "aws-amplify"
import "./about.scss"
import BackButton from "../images/backButton.png"

const AboutPage = () => {
  return (
    <section className="about">
      <SEO title="About" />
      <div className="padded">
        <Link to="/">
          <img className="back-button" src={BackButton}></img>
        </Link>
        <h1>Über das Projekt</h1>
        <p>
          Keplersgardens.info ist eine Kollaboration zwischen Ars Electronica
          und minuseins.
          <br></br>
          minuseins ist verantwortlich für die künstlerische und technische
          Realisation der Mozilla Hubs VR-Räume der Ars Electronica. Kontakt
          minuseins:{" "}
          <a href="mailto:leitung@minuseins.net">leitung@minuseins.net</a>
        </p>
        <h2>Leitung</h2>
        <p>
          <strong>Roman Senkl</strong> (Künstlerische Leitung)<br></br>
          <strong>Nils Corte</strong> (Technische Leitung)
        </p>
        <h2>Team</h2>
        <p>
          <strong>Nils Gallist</strong> (3D & Graphic Design)<br></br>
          <strong>Armin Luschin</strong> (Web Development, Backend & DevOps)
          <br></br>
          <strong>David Seul</strong> (3D Design)<br></br>
          <strong>Talking Animals Animation Collective</strong> (3D Design){" "}
          <br></br>
          <strong>Harald Günter Kainer</strong> (Sound Design)
        </p>
        <p>
          <a href="https://ars.electronica.art/keplersgardens/house-2-0/">
            Interactive Preview „Das HOUSE 0.2“{" "}
          </a>
          von minuseins. <br></br>
          <Link to="/project/818108c3-51eb-4022-8bda-ec6af1182e22">
            VR-Experience
          </Link>
        </p>
      </div>
    </section>
  )
}

export default AboutPage
